define("ember-autofocus-modifier/modifiers/autofocus", ["exports", "ember-modifier", "@ember/runloop"], function (_exports, _emberModifier, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_SELECTOR = 'input:not([disabled]):not([readonly]),textarea:not([disabled]):not([readonly])';
  var _default = _exports.default = (0, _emberModifier.modifier)(function autofocus(element, [selector = DEFAULT_SELECTOR], {
    disabled
  }) {
    if (disabled) {
      return;
    }
    const childElement = element.querySelector(selector);
    (0, _runloop.next)(function () {
      if (childElement) {
        childElement.focus();
      } else {
        element.focus();
      }
    });
  }, {
    eager: false
  });
});