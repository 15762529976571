define("ember-keyboard/utils/handle-key-event", ["exports", "ember-keyboard/utils/is-key"], function (_exports, _isKey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.handleKeyEventWithPropagation = handleKeyEventWithPropagation;
  function handleKeyEventWithPropagation(event, {
    firstResponders,
    normalResponders
  }) {
    let isImmediatePropagationStopped = false;
    let isPropagationStopped = false;
    const ekEvent = {
      stopImmediatePropagation() {
        isImmediatePropagationStopped = true;
      },
      stopPropagation() {
        isPropagationStopped = true;
      }
    };
    for (const responder of firstResponders) {
      triggerResponderListener(responder, event, ekEvent);
      if (isImmediatePropagationStopped) {
        break;
      }
    }
    if (isPropagationStopped) {
      return;
    }
    isImmediatePropagationStopped = false;
    let previousPriorityLevel = Number.POSITIVE_INFINITY;
    for (const responder of normalResponders) {
      const currentPriorityLevel = Number(responder.keyboardPriority);
      if (isImmediatePropagationStopped && currentPriorityLevel === previousPriorityLevel) {
        continue;
      }
      if (currentPriorityLevel < previousPriorityLevel) {
        if (isPropagationStopped) {
          return;
        }
        isImmediatePropagationStopped = false;
        previousPriorityLevel = currentPriorityLevel;
      }
      triggerResponderListener(responder, event, ekEvent);
    }
    /* eslint-enable no-unused-vars */
  }
  function triggerResponderListener(responder, event, ekEvent = null) {
    if (responder.handleKeyboardEvent) {
      if (responder.canHandleKeyboardEvent && !responder.canHandleKeyboardEvent(event)) {
        return;
      }
      responder.handleKeyboardEvent(event, ekEvent);
      return;
    }
    if (responder.keyboardHandlers) {
      Object.keys(responder.keyboardHandlers).forEach(responderListenerName => {
        if ((0, _isKey.default)(responderListenerName, event)) {
          if (ekEvent) {
            responder.keyboardHandlers[responderListenerName](event, ekEvent);
          } else {
            responder.keyboardHandlers[responderListenerName](event);
          }
        }
      });
      return;
    }
    throw new Error('A responder registered with the ember-keyboard service must implement either `keyboardHandlers` (property returning a dictionary of listenerNames to handler functions), or `handleKeyboardEvent(event)`)');
  }
});